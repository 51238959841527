import { environment } from '@chilipiper/config'
import { useEffect } from 'react'
import LogRocket from 'logrocket'

const APP_ID = 'cydyi9/customer-love'

type Options = {
  allowedEnvironments?: string[]
  appId?: string
  currentEnvironment?: string
}

const DEFAULT_OPTIONS: Required<Options> = {
  allowedEnvironments: ['canary', 'production'],
  currentEnvironment: environment.deploy,
  appId: APP_ID,
}

type User = { email?: string; id: string; tenantId?: string } & Record<
  string,
  string | number | boolean
>

export const useLogRocket = (user?: User, opts: Options = DEFAULT_OPTIONS) => {
  const { allowedEnvironments, currentEnvironment, appId } = { ...DEFAULT_OPTIONS, ...opts }

  const isEnabled = allowedEnvironments.includes(currentEnvironment)

  useEffect(() => {
    if (isEnabled) {
      LogRocket.init(appId)
    }
  }, [isEnabled, appId])

  useEffect(() => {
    if (isEnabled && user?.id) {
      logRocketIdentify(user)
    }
  }, [isEnabled, user?.id, user?.email, user?.tenantId])
}

export const logRocketIdentify = (user: User) => {
  const props: Record<string, string | number | boolean> = {}

  if (user.email) {
    props.email = user.email
  }

  if (user.tenantId) {
    props.tenantId = user.tenantId
  }

  LogRocket.identify(user.id, props)
}

export const logRocketTrack = (
  name: string,
  props?: Record<string, string | number | boolean | string[] | number[] | boolean[] | undefined>
) => {
  LogRocket.track(name, props)
}
