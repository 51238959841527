import { initializeModal } from '@chilipiper/design-system'
import { getSubdomain, maybeCanaryRedirect } from '@chilipiper/service/src/canary'
import { loadCustomCss } from '@chilipiper/service/src/inject-css'
import { initialize as initGTM } from '@chilipiper/service/src/gtm'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { defaultErrorCallback } from '@chilipiper/service/src/api'
import { GuestRouting } from './GuestRouting'
import './style_old.css'
import '@chilipiper/design-system/css/reset.css'

loadCustomCss()
maybeCanaryRedirect(getSubdomain()).catch(err => {
  defaultErrorCallback(err)
  // When this request fails, we flood the BE with other requests
  window.location.href = `${window.location.origin}/book/not-available`
})
if (process.env.NODE_ENV !== 'development') {
  initGTM()
}

console.log('booking-app-legacy version', __APP_VERSION__) // eslint-disable-line no-console

const initApp = () => {
  initializeModal()
  const container = document.getElementById('root')

  if (container) {
    createRoot(container).render(<GuestRouting />)
  }
}
initApp()
