import { DotsLoader, ThemeProviderClean } from '@chilipiper/design-system'
import { parseQuery } from '@chilipiper/utils'
import { AvailabilityProvider } from '@chilipiper/booking-flow'
import { WaitingComponent as WaitingComponentLib } from '@chilipiper/meetings-components/src/waiting-component'
import * as Adoption from '@chilipiper/service/src/adoption'
import { getSessionId, setSessionId } from '@chilipiper/service/src/session'
import { initTracking, setTrackingUserId, trackPageView } from '@chilipiper/service/src/tracking'
import isString from 'lodash/isString'
import React, { ComponentType, lazy, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import { useLogRocket } from '@chilipiper/tracking'

const RouterGuestApp = lazy(() => import('./router-guest-app/RouterGuestApp'))
const CancelMeeting = lazy(() => import('./cancel-meeting/CancelMeeting'))
const Booking = lazy(() => import('./Booking'))
const NotFound = lazy(() => import('./NotFound'))

const WaitingComponent = (Component: ComponentType<any>) =>
  WaitingComponentLib(Component, <DotsLoader color='neutral-dark-32' height='100%' />)

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const GuestRouting = () => {
  useLogRocket()
  useEffect(() => {
    Adoption.init()
    initTracking()
    const queryParams = parseQuery()
    if (isString(queryParams?.sessionId)) {
      setSessionId(queryParams.sessionId)
      setTrackingUserId(getSessionId())
    }
  }, [])

  return (
    <ThemeProviderClean>
      <AvailabilityProvider>
        <QueryClientProvider client={client}>
          <Router>
            <GuestTracking />
            <Switch>
              <Route path='/router/:routerName' component={WaitingComponent(RouterGuestApp)} />
              <Route path='/book/cancel/:reportId' component={WaitingComponent(CancelMeeting)} />
              <Route path='/book/*' component={WaitingComponent(Booking)} />
              <Route path='/reschedule/*' component={WaitingComponent(Booking)} />
              <Route path='*' component={WaitingComponent(NotFound)} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </AvailabilityProvider>
    </ThemeProviderClean>
  )
}

const GuestTracking = () => {
  const location = useLocation()
  useEffect(() => {
    trackPageView()
  }, [location])
  return null
}
