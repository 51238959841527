import React from 'react'
import styled from 'styled-components'
import { SpaceProps, SystemProps } from '../../new/theme'
import { Box, Flex } from '../../new/core-components/box'

type Props = SystemProps & {
  size?: SpaceProps['w']
}

const AnimatedLoader = styled(Flex)`
  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .dot1,
  .dot2,
  .dot3 {
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  .dot1 {
    animation-delay: -0.32s;
  }

  .dot2 {
    animation-delay: -0.16s;
  }
`

/** @deprecated - Use the `Loader` component from `@chilipiper/design-system/src/new` instead */
export const DotsLoader = ({ size = 4, color = 'bg/dots-loader', ...props }: Props) => {
  return (
    <AnimatedLoader
      data-test-id='DotsLoader'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <Box data-chili-css='loader-dot' className='dot1' w={size} h={size} bg={color} />
      <Box data-chili-css='loader-dot' className='dot2' w={size} h={size} bg={color} />
      <Box data-chili-css='loader-dot' className='dot3' w={size} h={size} bg={color} />
    </AnimatedLoader>
  )
}
