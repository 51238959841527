import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

interface Response {
  responseJSON?: string
  status?: number
  statusText?: string
  url?: string
}

interface AvailabilityContextType {
  availabilityState: {
    endpoint?: string
    response: Response
  }
  setAvailabilityState: Dispatch<SetStateAction<AvailabilityContextType['availabilityState']>>
}

const availabilityContext = createContext<AvailabilityContextType | undefined>(undefined)

export const AvailabilityProvider = ({ children }: { children: ReactNode }) => {
  const [availabilityState, setAvailabilityState] = useState<
    AvailabilityContextType['availabilityState']
  >({
    response: {},
  })
  const value = useMemo(() => ({ availabilityState, setAvailabilityState }), [availabilityState])
  return <availabilityContext.Provider value={value}>{children}</availabilityContext.Provider>
}

export const useAvailabilityContext = () => {
  const context = useContext(availabilityContext)
  if (context === undefined) {
    throw new Error('useAvailabilityContext must be used within AvailabilityProvider')
  }
  return context
}
